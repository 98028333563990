








































































































import { Component, Vue } from 'vue-property-decorator'
import { getAccounts, getOwnerGoat, refreshOwner, setOwnerGoat } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'

@Component({
})
export default class V4dev extends Vue {

  accounts: Account[] = []

  // hardcoded for now, should be retrieved from API
  levelsInfo = {
    "G0": {
      icon: "goat-wooden",
      advantages: [
        "Allow to receive weekly Wikibex articles and opportunities",
      ]
    },
    "G1": {
      icon: "goat-iron",
      advantages: [
        "Allow to participate to most of the ICOs with amount limit"
      ]
    },
    "G2": {
      icon: "goat-bronze",
      advantages: [
        "Secret",
      ]
    },
    "G3": {
      icon: "goat-bronze",
      advantages: [
        "Secret",
      ]
    },
    "G4": {
      icon: "goat-bronze",
      advantages: [
        "Allow to participate to all ICOs with no amount limit",
      ]
    }
  }

  goatLevels: any[] = []

  spotHelpURL = 'https://support.ib.exchange/hc/en-001/articles/9454002244253-SPOT-Create-a-BUY-or-a-SELL-order'
  spotURL: string = `${location.host}/spot/POWER_IBXE`
  upgradingToLevelId: string | null = null

  enabledLevel(levelId: string): boolean {
    let indexParam = this.goatLevels.findIndex(l => l.id == levelId)
    return this.goatLevels[indexParam].activated
  }

  powerBalanceEnoughToValidate(levelId: string): boolean {
    let indexParam = this.goatLevels.findIndex(l => l.id == levelId)
    return this.enabledLevel(levelId) && this.getBalancePOWER_MAIN() >= this.goatLevels[indexParam].lockedPOWER
  }

  validatedLevel(levelId: string): boolean {
    let indexParam = this.goatLevels.findIndex(l => l.id == levelId)
    let indexCurrentLevel = this.goatLevels.findIndex(l => l.id == this.$store.state.owner.goat.id)
    return indexCurrentLevel >= indexParam
  }

  getImage(level: any): string {
    if (this.$vuetify.theme.dark) {
      return `/goat/${level.id.toLowerCase()}_d.svg`
    } else {
      return `/goat/${level.id.toLowerCase()}_l.svg`
    }
  }

  async upgrade (levelId: string): Promise<void> {
    this.upgradingToLevelId = levelId
    let goat = null;
    for (const l of this.goatLevels) {
      if (l.id === levelId) {
        goat = l
        break
      }
    }
    await setOwnerGoat(this.$store.state.jwt, goat);
    await this.refresh()
    this.upgradingToLevelId = null
  }

  async mounted (): Promise<void> {
    await this.refresh()
  }

  async refresh (): Promise<void> {
    this.accounts = await getAccounts(this.$store.state.jwt, false)
    await refreshOwner(this.$store.state.jwt, this.$store)
    this.goatLevels = await getOwnerGoat(this.$store.state.jwt)
  }

  getBalancePOWER_MAIN (): number {
    for (const a of this.accounts) {
      if (a.currency === 'POWER' && a.type === 'MAIN') {
        return a.balance.toNumber()
      }
    }
    return 0
  }

  async openSpot (): Promise<void> {
    await this.$router.push('/spot/POWER_IBXE')
  }

  async openKYC (): Promise<void> {
    await this.$router.push('/ky')
  }

}
